body {
    --background-shadow: hsl(0deg 0% 99%);
    --background-0: #f4f7f9;
    --background-20: #f6f9fa;
    --background-40: #f8fafb;
    --background-60: #fbfcfd;
    --background-80: #fdfdfe;
    --background-100: #ffffff;
    --border-color: #edf0f4;
    --input-shadow: #f4f7f9;
    --button-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    --font-color: var(--brand--body--le--font--color);
    --color-subtle: #a4a6a8;
    --color-extra-subtle: #dde0e3;
    --font-color-nav-item: #a6aeba;

    --bg-highlight: var(--brand--le--bg--highlight);

    --color-primary: var(--brand--le--primary-color--base);
    --color-primary-variant: #86fdc1;
    --color-primary-variant-100: #cefde5;
    --color-primary-variant-200: #00a868;
    --color-primary-variant-300: #08912f;
    --color-primary-variant-400: #4eb48f;
    --color-primary-tint: var(--brand--de--primary-color--tint);
    --color-primary-shade: var(--brand--le--primary-color--shade);
    --color-secondary: var(--brand--le--secondary-color--base);
    --color-tertiary: var(--brand--le--tertiary-color--base);

    --color-danger: var(--brand--le--danger-color--base);
    --color-danger-shade: var(--brand--le--danger-color--shade);
    --color-danger-tint: var(--brand--le--danger-color--tint);

    --color-success: var(--brand--le--success-color--base);

    --default-stroke: #dadada;
    --color-gray_table: #999999;
    --color-gray_table-100: #677481;

    --color-gray: #d9d9d9;
    --emd-button_hover: #444648;
    --integration-step-hover: #dce0e9;
    --gray-bg: #edf0f4b2;
    --hb-button-font-color: #000000;
    --plus-button: #31383f;
    --integration-status: #fdf5ce;

    --special-font: #3b4652;

    --paper-plane: url('/assets/paper-plane.svg');
}

body._mode_dark {
    --background-shadow: #393a3b;
    --background-0: #1e1f20;
    --background-20: #202122;
    --background-40: #222324;
    --background-60: #232425;
    --background-80: #252627;
    --background-100: #272829;
    --input-shadow: #252627;
    --button-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

    --color-extra-subtle: black;
    --font-color: var(--brand--body--de--font--color);

    --bg-highlight: var(--brand--de--bg--highlight);

    --border-color: #393a3b;

    --color-primary: var(--brand--de--primary-color--base);
    --color-primary-variant-100: #a3d8b7;
    --color-primary-shade: var(--brand--de--primary-color--shade);
    --color-secondary: var(--brand--de--secondary-color--base);
    --color-tertiary: var(--brand--de--tertiary-color--base);

    --color-danger: var(--brand--de--danger-color--base);
    --color-danger-shade: var(--brand--de--danger-color--shade);
    --color-danger-tint: var(--brand--de--danger-color--tint);

    --color-gray: #393a3b;

    --color-success: var(--brand--de--success-color--base);

    --default-stroke: #393a3b;
    --emd-button_hover: #f1f2f3;
    --integration-step-hover: #2c2f33;
    --gray-bg: #393a3b;
    --hb-button-font-color: white;

    --special-font: #000;
    --paper-plane: url('/assets/paper-plane-dark.svg');
}
