@use '@angular/material' as mat;
@include mat.core();

.overflow-hidden {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// regular style toast
@import 'ngx-toastr/toastr';

@import './styles/colors.scss';
@import './styles/markdown.scss';
@import './styles/notification-toast.scss';
@import './styles/form.scss';

@font-face {
    font-family: StoneSharonFont;
    src: url('../public/assets/fonts/SharonSnRm-VF.ttf') format('trueType');
}

@font-face {
    font-family: CircularStdBold;
    src: url('../public/assets/fonts/CircularStdBold.ttf') format('trueType');
}

@font-face {
    font-family: CircularStdMedium;
    src: url('../public/assets/fonts/CircularStdMedium.ttf') format('trueType');
}

.stoneSharonFont {
    font-family: StoneSharonFont, sans-serif;
}

.circularStdBold {
    font-family: CircularStdBold, sans-serif;
}

.circularStdMedium {
    font-family: CircularStdMedium, sans-serif;
}

.spinner-text-size {
    width: 1rem;
    height: 1rem;
}

._active .emd-action-button:after {
    background-color: initial !important;
}

ngb-popover-window {
    border-style: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px !important;

    .arrow {
        display: none !important;
    }

    .popover-body {
        padding: 0 !important;
    }
}

.emd-dropdown__content {
    z-index: 98 !important;
}

.emd-tabbar__item,
.emd-tabbar__link {
    z-index: inherit !important;
}

iframe.app-youtube-iframe {
    width: 100%;
    height: 100%;
}

.app-custom-scrollbar {
    &::-webkit-scrollbar {
        margin-top: 1rem;
        width: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-extra-subtle);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--background-0);
    }
}

.custom-control-input:enabled + .custom-control-label {
    cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--color-primary) !important;
    background-color: var(--color-primary) !important;
}

.custom-control-input:checked:disabled ~ .custom-control-label::before {
    opacity: 0.7;
    border-style: none;
}

.app-max-width-icon {
    max-width: 1.5rem;
}

.app-max-width-text-small {
    max-width: 7rem !important;
}

ngb-modal-backdrop {
    z-index: 999998 !important;
}

.modal {
    z-index: 999999;
}

.toast-container {
    z-index: 99999999 !important;
}

.cdk-overlay-container {
    z-index: 9999999 !important;
}

.hub-default-form {
    display: flex;
    flex-wrap: wrap;

    gap: 1rem;
}

.green-divider {
    border-top: 3px solid #29dc7a;
}

.tab {
    font-weight: 700;
    padding: 8px 42px;
    border-radius: 15px;
    border-style: none;
    background-color: #edf0f4;
}

.tab:hover {
    cursor: pointer;
}

.selected-tab {
    color: #00a868;
    border: solid 3px #00a868;
    background-color: #fff;
}

.hub-default-input-gap {
    gap: 0.75rem;
}

.hb-card {
    border-radius: 4px !important;
    padding: 1rem;
}

.modal-remuneration-size {
    max-width: 37.375rem;
    max-height: 27.438rem;
}

.modal-oc-size {
    max-height: 20.813rem;
    max-width: 40rem;
}

.stone-account-benefits-size {
    max-width: 37.5rem;
    max-height: 43.75rem;
}

.modal-stone-code-size {
    max-width: 31.25rem;
    min-height: 31.5rem;
}

.hub-table {
    border-collapse: separate !important;
    border-spacing: 0 0.75rem !important;

    th {
        padding: 0.625rem 1rem 0.625rem 1rem !important;
    }

    td {
        padding: 0.5rem 1rem 0.5rem 1rem !important;
        color: var(--brand--table--le--head);
    }

    th:nth-child(1),
    td:nth-child(1) {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    th:nth-last-child(1),
    td:nth-last-child(1) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.modal-600 {
    --bs-modal-width: 600px;
}

.boder-none {
    border: none !important;
}

.hb-button {
    background-color: var(--color-primary-variant) !important;
    border: 2px solid var(--color-primary-variant) !important;
    box-shadow: 0px 0px 4px 0px #67748133;
    padding: 0.5rem;
    white-space: nowrap;
    color: #1d242b !important;
    font-weight: 800;
    align-items: center;
    border-radius: 8px;
    height: fit-content;

    &.danger {
        background-color: transparent !important;
        color: #ee7676 !important;
        border-color: #ee7676 !important;
    }
}

.outline {
    background-color: transparent !important;
    border: 2px solid #29dc7a !important;
    color: #29dc7a !important;
    box-shadow: 0px 0px 4px 0px #67748133;
}

.hb-button:hover {
    opacity: 0.6 !important;
}

.hb-button:disabled {
    background-color: var(--border-color) !important;
    border-color: var(--border-color) !important;
    cursor: not-allowed;
}

@media (max-width: 480px) {
    .hub-default-form {
        display: flex;
        flex-direction: column;

        gap: 1rem;
    }
}

@media (max-width: 1680px) {
    body {
        overflow-x: auto;
    }
}
.mat-mdc-dialog-surface {
    border-radius: 16px !important;
}
